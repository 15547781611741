<template>
  <div class="walletConnect">
    <w3m-button id="web3button" size="md" label="Wallet Connect"/>
  </div>
</template>

<script setup>
  import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'
  import { useStore } from "vuex"
  import { reconnect,  http, watchConnections } from '@wagmi/core'
  import { mainnet, bsc, okc } from '@wagmi/core/chains'
  import { ref, inject, getCurrentInstance } from 'vue'
  import { useI18n } from 'vue-i18n'
  import data_con  from '@/utils/wallet_config.js'
  import logo from "@/assets/image_img/logo.png"
  const { proxy } = getCurrentInstance();
  const { t } = useI18n();
  const store = useStore()
  // 1. Define constants
  const projectId = 'b4e41353f250958b0c77472ed603222a'
  // 2. Create wagmiConfig
  const metadata = {
    name: 'DeepLink',
    description: 'DeepLink',
    url: 'https://www.deeplink.cloud/', // origin must match your domain & subdomain
    // url: 'http://localhost:8099/', // origin must match your domain & subdomain
    icons: [ logo ]
  }
  const chains = [
    // mainnet, 
    bsc, 
    // okc
  ]
  const config = data_con
  store.commit('setConfig', config)
  try {
    reconnect(config).then( async res => {
      if (res.length) {
        store.commit('setConnectWallet', res[0].accounts[0])
      } else {
        store.commit('setConnectWallet', '')
      }
    })
    watchConnections(config, {
      async onChange(data) {
        if (data.length) {
          store.commit('setConnectWallet', data[0].accounts[0])
        } else {
          store.commit('setConnectWallet', '')
        }
      },
    })
  } catch (error) {
    console.log(error, 'error');
  }
  // 3. Create modal
  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
    featuredWalletIds: [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
      '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662', // bitget
      'aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588', // gateweb3
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0' // trust wallet
    ]
  })
</script>

<style>
:root {
  --wui-font-weight-medium: 400 !important;
  --wui-color-accent-100: rgba(255, 255, 255, 0.1) !important;
  --wui-color-accent-090: #ebebeb !important;
  --wui-border-radius-m: 24px !important;
}
</style>