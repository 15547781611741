<template>
  <div class="footer" :class="{lan_En : lan != 'zh',  w1200: isphone && href == '/Protocol' }">
    <div class="foot_bg"></div>
    <div class="content ">
      <img class="logo" src="../assets/logo.png" alt="">
      <div class="foot_text">{{$t('footer.text')}}</div>
      <div class="foot_icon">
        <div class="list" v-for="item in hrefObj" @click="showModel(item.link)">
          <div class="li_img"><img :src="item.img" alt=""></div>
          <div class="li_country" v-if="item.img_cry"><img :src="item.img_cry" alt=""></div>
        </div>
      </div>
      <div class="foot_cont">
        <div class="list">
          <p class="title">{{$t('footer.meau1')}}</p>
          <p class="text" @click="linkHref('/node')">{{$t('meau.nav1')}}</p>
          <a class="nav1" href="https://orion.deeplink.cloud" target="_blank">{{$t('meau.nav2')}}</a>
          <p class="text" @click="linkHref('/bandWidth')">{{$t('meau.nav4')}}</p>
          <p class="text" @click="linkHref('/blog')">{{$t('meau.nav6')}}</p>
          <a href="https://www.deeplink.cloud/public-sale/" target="_blank">{{ $t('lanchpad.cont1.btn4') }}</a>
          <a href="https://www.deeplink.cloud/DeepLink_Whitepaper.pdf" target="_blank">WHITEPAPER</a>
          <a class="nav1" href="https://github.com/DeepLinkProtocol" target="_blank">Github</a>
          <a class="nav1" href="https://www.deepbrainchain.org/" target="_blank">DeepBrain Chain</a>
        </div>
        <div class="list">
          <p class="title">{{$t('footer.meau2')}}</p>
          <a class="text nav1" href="https://static1.deeplink.cloud/deeplink_app.apk" target="_blank">{{$t('cont1.btn3')}}</a>
          <a class="text nav1" href="https://static1.deeplink.cloud/DeeplinkSetup.exe" target="_blank">{{$t('cont1.btn1')}}</a>
          <a class="text nav1" href="https://play.google.com/store/apps/details?id=cloud.deeplink" target="_blank">{{$t('cont1.btn4')}}</a>
          <a class="text nav1" href="https://rtc.deeplink.cloud/" target="_blank">{{$t('cont1.btn2')}}</a>
          <a class="text nav1" href="https://github.com/DeepLinkProtocol/DeepLinkSDK-Win" target="_blank">{{$t('footer.btn5')}}</a>
        </div>
        <div class="list">
          <p class="title">{{$t('footer.meau3')}}</p>
          <a class="text nav1" href="https://bscscan.com/token/0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC" target="_blank">{{$t('footer.meau3_text1')}}</a>
          <a class="text nav1" href="https://dbc.subscan.io/holder?asset_id=88" target="_blank">{{$t('footer.meau3_text2')}}</a>
          <a class="text nav1" href="https://dbcwallet.io/" target="_blank">{{$t('footer.meau3_text3')}}</a>
        </div>
        <div class="list">
          <p class="title">{{$t('footer.meau4')}}</p>
          <a class="text nav1" href="https://i.bybit.com/1rvLabpF" target="_blank">{{$t('footer.meau4_text1')}}</a>
          <a class="text nav1" href="https://app.galxe.com/quest/OKXWEB3/GCZ7vtVQD3" target="_blank">{{$t('footer.meau4_text2')}}</a>
          <a class="text nav1" href="https://www.binance.com/en/web3-campaign/airdrop/4311523698914080000" target="_blank">{{$t('footer.meau4_text3')}}</a>
        </div>
      </div>
    </div>
    <!-- <div class="content show">
      <img class="logo" src="../assets/logo.png" alt="">
      <div class="link">
        <p class="title">{{$t('footer.link')}}</p>
        <p><a href="https://www.deeplink.cloud/DeepLink_Whitepaper.pdf" target="_blank">WHITEPAPER</a></p>
      </div>
      <div class="community">
        <p class="title">{{$t('footer.community')}}</p>
        <div class="follow">
          <div class="list" v-for="item in hrefObj" @click="showModel(item.link)">
            <div class="li_img"><img :src="item.img" alt=""></div>
            <div class="li_country" v-if="item.img_cry"><img :src="item.img_cry" alt=""></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted, computed } from "vue";
  import { useI18n } from "vue-i18n";
  import { useRouter } from 'vue-router'
  import { useStore } from "vuex"
  import twitter from "../assets/ri_twitter.png";
  import telegram from "../assets/ri_telegram.png";
  import medium from "../assets/ri_medium.png";
  import youtube from "../assets/ri_youtube.png";
  import facebook from "../assets/ri_facebook.png";
  import instagram from "../assets/ri_instagram.png";
  import tiktok from "../assets/ri_tiktok.png";
  import discord from "../assets/ri_discord.png";
  import ja_cry from "../assets/Japan.png";
  import ar_cry from "../assets/ar.png";
  import ve_cry from "../assets/ve.png";
  import in_cry from "../assets/in.png";
  import kr_cry from "../assets/kr.png";
  import zh_cry from "../assets/zh.png";
  export default defineComponent({
    name: 'header',
    setup() {
      const router = useRouter()
      // const lan = ref(localStorage.getItem('lan') || 'en')
      const store = useStore()
      let lan = computed(() => store.state.lan)
      const href = computed(() => store.state.path )
      const text = ref('简体中文')
      const { locale } = useI18n()
      const dialogTableVisible = ref(false)
      const ishover = ref(false)
      const dialogTitle = ref('')
      const lanObj = {
        'en': 'ENGLISH',
        'ko': '한국어',
        'zh': '简体中文'
      }
      const hrefObj = ref([
        {
          img: twitter,
          link: 'https://twitter.com/DeepLinkGlobal'
        },
        {
          img: twitter,
          img_cry: ja_cry,
          link: 'https://x.com/DeepLinkJapan'
        },
        {
          img: telegram,
          link: 'https://t.me/deeplinkglobal'
        },
        {
          img: telegram,
          img_cry: zh_cry,
          link: 'https://t.me/DeepLinkChina'
        },
        {
          img: telegram,
          img_cry: kr_cry,
          link: 'https://t.me/deeplinkkorea'
        },
        {
          img: telegram,
          img_cry: ja_cry,
          link: 'https://t.me/DeepLinkJapan'
        },
        {
          img: discord,
          link: 'https://discord.com/invite/hCSAF3QC8U'
        },
        // {
        //   img: tiktok,
        //   link: 'https://www.tiktok.com/@deeplinkglobal'
        // },
        {
          img: instagram,
          link: 'https://instagram.com/deeplinkglobal',
        },
        // {
        //   img: facebook,
        //   link: 'https://www.facebook.com/deeplinkcloud',
        // },
        {
          img: youtube,
          link: 'https://youtube.com/@deeplinkglobal'
        },
        {
          img: medium,
          link: 'https://medium.com/@DeepLinkGlobal'
        },
        // {
        //   img: telegram,
        //   img_cry: in_cry,
        //   link: 'https://t.me/DeepLinkIndia'
        // },
        // {
        //   img: telegram,
        //   img_cry: ve_cry,
        //   link: 'https://t.me/DeepLinkVietnamese'
        // },
        // {
        //   img: telegram,
        //   img_cry: ar_cry,
        //   link: 'https://t.me/DeepLinkAfrica'
        // },
      ])
      const showObj = ref({})
      const showModel = (command) => {
        window.open(command, '_blank')
      }
      const change = (el) => {
        ishover.value = el
      }
      const linkHref = (command) => {
        if (command == '/Whitepaper') {
          if (lan.value == 'zh') {
            window.open('/DeepBrainChainWhitepaper_zh.pdf');
          } else {
            window.open('/DeepBrainChainWhitepaper_en.pdf');
          }
          return
        } else if(command == '/WiKi') {
          window.open('https://deepbrainchain.github.io/DBC-Wiki/');
          return
        }
        router.push({path: command})
        href.value = command
        localStorage.setItem('path', command)
        store.commit('changePath', command)
      }
      const isPc = () => {
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
      }
      const isphone = ref(false)
      onMounted(() => {
        text.value = lanObj[lan.value]
        isphone.value = isPc()
      });
      return {
        lan,
        text,
        href,
        hrefObj,
        dialogTableVisible,
        dialogTitle,
        showObj,
        ishover,
        isphone,
        showModel,
        change,
        linkHref
      };
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  width: 100%;
  margin: auto;
  // height: 300px;
  overflow: hidden;
  max-width: 1200px;
  font-style: normal;
  background: #000; 
  position: relative;
  .foot_bg {
    position: absolute;
    z-index: 1;
    bottom: -500px;
    right: -200px;
    width: 1030px;
    height: 1030px;
    background: url(../assets/footbg.png) no-repeat 100%/100%;
  }
  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    // height: 300px;
    margin: auto;
    padding: 30px 0;
    box-sizing: border-box;
    .logo {
      width: 265px;
      height: 26px;
      margin-bottom: 30px;
    }
    .foot_text {
      width: 900px;
      color: #fff;
      font-size: 20px;
      font-family: Monda;
    }
    .foot_icon {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      .list {
        padding: 10px;
        cursor: pointer;
        border-radius: 8px;
        align-items: center;
        display: inline-flex;
        margin: 25px 10px 0 0;
        backdrop-filter: blur(6px);
        background: hsla(0, 0%, 100%, .1);
        .li_img {
          width: 24px;
          height: 24px;
          img {
            width: 24px;
          }
        }
        .li_country {
          width: 20px;
          margin-left: 5px;
          img {
            width: 20px;
          }
        }
      }
    }
    .foot_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .list {
        line-height: 28px;
        font-family: Monda;
        .title {
          color: #FFF;
          height: 55px;
          font-size: 26px;
          font-weight: 600;
          margin-bottom: 12px;
          text-transform: uppercase;
        }
        .text {
          cursor: pointer;
          font-size: 20px;
          margin-bottom: 5px;
          color: rgba(255, 255, 255, .6);
          &:hover {
            color: #fff;
          }
        }
        a {
          display: block;
          cursor: pointer;
          font-size: 20px;
          margin-bottom: 5px;
          text-decoration: none;
          color: rgba(255, 255, 255, .6);
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.detailsInfo {
  width: 100%;
  text-align: center;
  .img {
    width: 100%;
    display: flex;
    overflow: hidden;
    max-height: 350px;
    align-items: center;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 14px;
    color: #1F1F1F;
    margin-top: 10px;
    a {
      text-decoration: none;
    }
  }
 
}


@media screen and (max-width: 1200px) {
  .footer {
    min-width: 600px;
    height: initial;
    padding-bottom: 30px;
    .content {
      width: 90%;
      height: initial;
      padding: 30px 0;
      .logo {
        margin-bottom: 20px;
      }
      .foot_text {
        width: 100%;
        font-size: 18px;
      }
      .foot_icon { 
        flex-wrap: wrap;
        margin-bottom: 40px;
      }
      .foot_cont {
        .list {
          line-height: 22px;
          .title {
            height: 45px;
            font-size: 20px;
          }
          .text {
            font-size: 16px;
          }
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
  .lan_En.footer {
    .content {
      padding: 30px 0 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .footer {
    .content {
      .foot_cont {
        flex-wrap: wrap;
        .list {
          width: 50%;
          line-height: 18px;
          margin-bottom: 20px;
          .title {
            height: 40px;
            font-size: 20px;
          }
          .text {
            font-size: 14px;
          }
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
  .lan_En.footer {
    .content {
      padding: 30px 0 0;
    }
  }
}
</style>